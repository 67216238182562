import { environment } from "./environment.constants";

type Branding = {
  name?: string;
  imageSet?: string;
  graphicSet?: string;
  productNames?: {
    trainings?: { en?: string; nl?: string };
    playground?: { en?: string; nl?: string };
    practitioner?: { en?: string; nl?: string };
    practitioners?: { en?: string; nl?: string };
    questionnaire?: { en?: string; nl?: string };
    questionnaires?: { en?: string; nl?: string };
  };
  ssoLabel?: string;
  restrictions?: {
    canRegisterAsProfessional?: boolean;
    canRegisterAsClient?: boolean;
    canSeeBannerImage?: boolean;
  };
  features?: {
    hasWeekResultReports?: boolean;
    hasPushNotifications?: boolean;
    hasIntroductionContent?: boolean;
    hasMindgrapherContent?: boolean;
    isMapEnabled?: boolean;
    externalPurchaseButton?: {
      title: string;
      url: string;
    };
    externalPractitionerSso?: {
      title: string;
      url: string;
    };
    externalSearch?: {
      title: string;
      body: string;
      url: string;
    };
  };
  urls?: {
    homepage?: string;
    privacyPolicy?: string;
    termsAndConditions?: string;
  };
  videoUrls?: {
    introduction?: string;
    addCollectionPractitionerInstruction?: string;
    addCollectionClientInstruction?: string;
    explanationOfGraph?: string;
  };
  mailAddresses?: {
    support?: string;
  };
  map?: {
    initialLongitude?: number;
    initialLatitude?: number;
    mapboxStyle?: string;
  };
  colors?: { [key: string]: string };
};

const branding: Branding = (() => {
  try {
    const branding = JSON.parse(environment.branding);
    return branding;
  } catch (exception) {
    return {};
  }
})();

if (branding.colors) {
  Object.keys(branding.colors).forEach((colorKey) => {
    if (!branding.colors || !branding.colors[colorKey]) {
      return;
    }
    const variableName = colorKey
      .replace(/([a-z])([A-Z])/g, "$1-$2")
      .replace(/([0-9])([a-z])/g, "$1-$2")
      .replace(/([a-z])([0-9])/g, "$1-$2")
      .toLowerCase();
    document.documentElement.style.setProperty(
      `--rgb-color-${variableName}`,
      branding.colors[colorKey],
    );
  });
}

export { branding };
