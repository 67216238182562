import { ICredentialsBody, ISessionToken } from "@hulanbv/platformapp";
import { CrudService, IHttpOptions, IResponse } from "nest-utilities-client";
import { environment } from "../../constants/environment.constants";
import { httpService } from "../common/http.service";
import { userUtils } from "../user/user.utils";

class Service extends CrudService<ISessionToken> {
  private sessionStorageKey = "frontend-session";

  constructor() {
    super([environment.apiUrl, "authentication"].join("/"), httpService);
  }

  /**
   * Create an authenticated session
   * @param credentials FormData object with `username` and `password`
   */
  async login(
    credentials: ICredentialsBody | FormData,
    options?: IHttpOptions,
  ): Promise<IResponse<ISessionToken>> {
    const response = await this.http.post(
      this.controller,
      credentials,
      options,
    );
    return response;
  }

  /**
   * Disable the current authorization token
   * @param options
   * @returns
   */
  async logout(options?: IHttpOptions): Promise<IResponse<void>> {
    try {
      const response = await this.http.post(
        [this.controller, "logout"].join("/"),
        null,
        options,
      );
      return response;
    } finally {
      this.setSession(null);
    }
  }

  /**
   * Validate the currently used authorization token
   * @param options
   * @returns
   */
  async validate(options?: IHttpOptions): Promise<IResponse<ISessionToken>> {
    const response = await this.http.post(
      [this.controller, "validate"].join("/"),
      null,
      {
        ...options,
        populate: [
          ...(options?.populate ?? []),
          "user.license",
          "user.practitioner",
        ],
      },
    );
    return response;
  }

  /**
   * Store the session token in the localstorage
   * @param session
   */
  setSession(session: ISessionToken | null) {
    if (session) {
      localStorage.setItem(this.sessionStorageKey, JSON.stringify(session));
      // after the session has been set we want to make sure the userStore in
      // the localStorage is been defined
      userUtils.initializeUserStore();
    } else {
      localStorage.removeItem(this.sessionStorageKey);
    }
  }

  /**
   * Retrieve the session token from the local storage
   * @returns
   */
  getSession(): ISessionToken | null {
    // attempt to parse the stored string to a JSON object
    try {
      const session = localStorage.getItem(this.sessionStorageKey) ?? null;
      return session ? JSON.parse(session) : null;
    } catch {
      return null;
    }
  }

  getSsoUrl(): string {
    return [this.controller, "sso", "azure", "authenticate"].join("/");
  }
}

export const authenticationService = new Service();
