import { ISsoToken } from "@hulanbv/platformapp";
import { CrudService, IHttpOptions, IResponse } from "nest-utilities-client";
import { httpService } from "./common/http.service";

class Service extends CrudService<ISsoToken> {
  constructor() {
    super([process.env.REACT_APP_API_URL, "sso-tokens"].join("/"), httpService);
  }

  request(
    httpOptions?: IHttpOptions<ISsoToken>,
  ): Promise<IResponse<ISsoToken>> {
    return this.http.post<ISsoToken>(
      [this.controller, "request"].join("/"),
      null,
      httpOptions,
    );
  }
}

export const ssoTokensService = new Service();
