import { FC, useState, useEffect } from "react";
import { style } from "typestyle";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { routes } from "../../state/common/constants/routes.constants";
import { InjectionService } from "../../state/common/injection.service";
import { Router } from "../elements/router.element";
import Toast from "../statics/toast";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { authenticationService } from "../../state/authentication/authentication.service";

export const App: FC = () => {
  const authHook = useAuthContext();
  const [isValidated, setIsValidated] = useState(false);

  useEffect(() => {
    // if the url contains a ?token, store it before validating
    const token = new URLSearchParams(window.location.search).get("token");
    if (token) {
      authenticationService.setSession({
        token,
        userId: "",
        timestamp: new Date(),
      });

      // remove the token from the url
      window.history.replaceState({}, document.title, window.location.pathname);
      window.close();
    }

    authHook
      .validate()
      .catch(async () => {
        await authHook.logout();
        Toast.error({
          body: dictionary.texts.autoSignInError,
        });
      })
      .finally(() => {
        setIsValidated(true);
        InjectionService.showWebview();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- just run the validation once
  }, []);

  return (
    <div className={styles.app}>
      {isValidated === true && <Router routes={Object.values(routes)} />}
    </div>
  );
};

const styles = {
  app: style({
    display: "flex",
    minHeight: "100vh",
    width: "100%",
    position: "relative",
  }),
};
