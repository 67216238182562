import {
  IQuestion,
  IExercise,
  IQuestionnaire,
  ExerciseType,
  ExerciseTag,
  QuestionnaireType,
} from "@hulanbv/platformapp";
import Toast from "../components/statics/toast";
import { dictionary } from "../state/common/constants/dictionary.constants";
import { exerciseService } from "../state/exercise/exercise.service";
import { questionService } from "../state/question/question.service";
import { questionnaireService } from "../state/questionnaire/questionnaire.service";

export const createExerciseWithQuestionnaire = async (
  title: string,
  description: string,
  selectedQuestions: IQuestion[],
  ownerId: string,
): Promise<IExercise | null> => {
  let createdExercise: IExercise | null = null;

  try {
    // create new questionnaire
    const newQuestionnaire: IQuestionnaire = {
      title,
      description,
      ownerId,
      type: QuestionnaireType.DEFAULT,
    };
    // post new questionnaire
    const { data: createdQuestionnaire } = await questionnaireService.post(
      newQuestionnaire,
    );

    // Create all the needed questions for the questionnaire
    const questions: IQuestion[] = [];
    for (const [index, question] of selectedQuestions.entries()) {
      questions.push({
        ...question,

        // set the contextual questionnaireId and questionIndex
        questionIndex: index,
        questionnaireId: createdQuestionnaire._id,

        // unset populatable fields
        questionAnswers: undefined,
        userAnswers: undefined,
        questionnaire: undefined,
      });
    }

    // Post all the newly created questions
    await Promise.all(
      questions.map((question) => questionService.post(question)),
    );

    // Create a new exercise with the questionnaire
    // HACK: Creating a formData object to send through the post request. This is done
    // because there are values (isDefaultAssigned, isDefaultLocked) that are not getting
    // accepted by the backend when they are sent as a JSON object.
    const exerciseFormData = new FormData();
    exerciseFormData.append("name", title);
    exerciseFormData.append("type", String(ExerciseType.TEXT));
    exerciseFormData.append("description", description);
    exerciseFormData.append("questionnaireId", createdQuestionnaire._id);
    exerciseFormData.append("ownerId", ownerId);
    exerciseFormData.append("tags[]", String(ExerciseTag.PLAYGROUND));

    // Post the new exercise
    const response = await exerciseService.post(exerciseFormData);
    // Set the created exercise
    createdExercise = response.data;
  } catch {
    Toast.error({
      body: dictionary.texts.exerciseCreationError,
    });
  }

  return createdExercise;
};
